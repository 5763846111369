export default {
	methods: {
		addClassOnFocus() {
			// zooming for focused inputs is disabled by default for iframes to resolve #24823
			// The CSS that disables zoom (touch-action: pan-x pan-y;) will no longer apply after this attribute is added.
			setTimeout(() => {
				if (this.$el.querySelector('input, textarea'))
					this.$el.querySelector('input, textarea').setAttribute('data-allow-zoom', 'true');
			}, 1000);
			if (this.getField(this.$parent))
				this.getField(this.$parent).isFocused = true;
		},
		removeClassOnBlur() {
			if (this.$el.querySelector('input, textarea'))
				this.$el.querySelector('input, textarea').removeAttribute('data-allow-zoom');
			if (this.getField(this.$parent))
				this.getField(this.$parent).isFocused = false;
		},
		getField(el) {
			if (el.$refs.field)
				return el;
			else {
				if (el.$parent)
					return this.getField(el.$parent);
				else
					return null;
			}
		}
	}
};